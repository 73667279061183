<script setup>
import {ref, computed, watch} from "vue";
import {usePage} from "@inertiajs/inertia-vue3";
import {throttle} from "lodash/function";
import {pickBy} from "lodash";
import {Inertia} from "@inertiajs/inertia";
import Pagination from "@/Components/Pagination.vue";
import {notify} from "@kyvg/vue3-notification";
import Modal from "@/Components/Modal.vue";
import InformationsUser from "@/Pages/Administrators/partials/InformationsUser.vue";

const emit = defineEmits(['editItem']);

/**
 * Data for the datatable - List of administrators
 * @type {ComputedRef<*>}
 */
const administrators = computed(() => usePage().props.value.users );
const props = defineProps({
    currentItem: {
        type: Object,
        default:null
    },
});

const roles = computed(() => usePage().props.value.roles)
const organismes = computed(() => usePage().props.value.organismes_list)
/**
 * Filter received from the controller
 * @type {ComputedRef<unknown>}
 */
const filters = computed(() => usePage().props.value.filters );

/**
 * Params send to the controller
 * @type {Ref<UnwrapRef<{search, field: *, direction}>>}
 */
const params = ref({
    search:filters.value.search,
    field:filters.value.field,
    direction:filters.value.direction,
    role:filters.value.role ?? 'all',
    organisme:filters.value.organisme ?? 'all',
});


/**
 * Sort handler on columns header
 * @param field
 */
const sort = (field) => {
    editItem(null);
    params.value.field = field
    params.value.direction = params.value.direction === 'asc' ? 'desc' : 'asc';
}

/**
 * Watcher for params
 * Make an Inertia request with cleaned params
 */
watch(params, throttle(function (){
    //clean empty params
    let my_params = pickBy(params.value);

    if(my_params.role === 'all'){
        delete  my_params.role
    }
    if(my_params.organisme === 'all'){
        delete  my_params.organisme
    }

    //request
    Inertia.get(route('admin.administrators.index'), my_params, {replace:true, preserveState:true})
}, 300), {deep:true})

const editItem = (item) =>  emit("editItem", item);

const openSupressModal = (item) => {
    editItem(null);
    itemToDelete.value = item;
    admin_name.value = item.fullname;
};
const closeSupressModal = () => {
    itemToDelete.value = null;
};
const showSuppressModal = computed( () => {
    return itemToDelete.value !== null ;
} )
const itemToDelete = ref(null);
const formDeleteProcessing = ref(false);
const admin_name = ref('');
const maxRoleWeightCurrentUser = computed(() => usePage().props.value.auth.user.maxRoleWeight );
const disabledStateDeleteButton = (user) => {
    return maxRoleWeightCurrentUser.value < user.maxRoleWeight || usePage().props.value.auth.user.id === user.id;
}
const isAccountActif = (user) => {
    const metas = user.user_metas.filter(meta => meta.meta_key === "is_active_account");
    if(metas.length > 0){
        const meta = metas[0];
        return parseInt(meta.meta_value);
    }
    return null
}
const submitDelete = () => {
    formDeleteProcessing.value = true;
    Inertia.delete(route('admin.administrators.destroy', itemToDelete.value.id), {
        replace:true,
        preserveState:true,
        onSuccess: (page) => {
            itemToDelete.value = null;
            const success_msg = page.props.flash.success;
            if (success_msg) {
                notify({
                    title: usePage().props.value.language['ui.administrator.Deleted'],
                    text: usePage().props.value.language['ui.administrator.SuccessMsgDeleted'],
                    type: "success",
                })
            }
        },
        onError: (errors) => {console.log(errors);},
        onFinish: () => formDeleteProcessing.value = false
    })
}
</script>
<template>
    <Modal id="suppress_modal" :show="showSuppressModal" @close="closeSupressModal">
        <template #title>
            <i class="fas fa-trash-alt"></i> {{ __('ui.administrator.DeleteAnAdministrator') }}
        </template>
        <template #content>
            <form @submit.prevent="submitDelete">
                <div class="modal-body">
                    {{ __('ui.administrator.MessageToDeleteUser') }} "<strong class="text-info">{{ admin_name }}</strong>" ?
                </div>
                <div class="modal-footer">
                    <button type="button" @click="closeSupressModal" class="btn btn-secondary">{{ __('ui.administrator.Cancel') }}</button>
                    <button type="submit" :disabled="formDeleteProcessing" class="btn btn-danger">{{ __('ui.role.Delete') }}</button>
                </div>
            </form>
        </template>
    </Modal>


    <div class="card animate">
        <div class="card-header">
            <h3 class="card-title"><i class="fas fa-list"></i> Liste des comptes existants</h3>
        </div>
        <div class="card-body">
            <div id="administrators_wrapper" class="dataTables_wrapper dt-bootstrap4">
                <div class="row">
                    <div class="col-sm-6">
                        <template v-if="roles.length > 1">
                            <div class="form-inline mb-2">
                                <label for="role_filter" class="font-weight-normal">Filtrer par rôle : </label>
                                <select v-model="params.role" id="role_filter" class="form-control form-control-sm ml-1">
                                    <option value="all">Tous</option>
                                    <option v-for="role in roles" :key="role.slug" :value="role.slug">{{role.name}}</option>
                                </select>
                            </div>
                        </template>
                        <template v-if="organismes.length > 1">
                            <div class="form-inline mb-2">
                                <label for="organisme_filter" class="font-weight-normal">Filtrer par organisme : </label>
                                <select v-model="params.organisme" id="organisme_filter" class="form-control form-control-sm ml-1">
                                    <option value="all">Tous</option>
                                    <option v-for="organisme in organismes" :key="organisme.user_id" :value="organisme.user_id">{{organisme.meta_value}}</option>
                                </select>
                            </div>
                        </template>
                    </div>
                    <div class="col-sm-6">
                        <div id="administrators_filter" class="dataTables_filter"><label>Rechercher dans la liste :
                                <input
                                type="search" class="form-control form-control-sm" v-model="params.search" placeholder=""
                                aria-controls="administrators"></label></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="table-responsive">
                        <table id="administrators"
                                   class="table table-bordered table-hover table-striped dataTable dtr-inline table-sm"
                                   role="grid" aria-describedby="administrators_info">
                                <thead>
                                <tr role="row">
                                    <th class="col-2 sorting" :class="{'sorting_asc': params.field === 'firstname' && params.direction === 'asc','sorting_desc': params.field === 'firstname' && params.direction === 'desc' }" tabindex="0"
                                        @click="sort('firstname')"
                                        aria-controls="administrators"
                                        rowspan="1" colspan="1" aria-sort="ascending"
                                        aria-label="Nom: cliquez pour trier par ordre décroissant">
                                        {{ __('ui.administrator.Firstname') }}
                                    </th>
                                    <th class="col-2 sorting" :class="{'sorting_asc': params.field === 'lastname' && params.direction === 'asc','sorting_desc': params.field === 'lastname' && params.direction === 'desc' }" tabindex="0" aria-controls="administrators"
                                        @click="sort('lastname')"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Nom: cliquez pour trier par ordre croissant">
                                        {{ __('ui.administrator.Name') }}
                                    </th>
                                    <th class="col-3 sorting" :class="{'sorting_asc': params.field === 'email' && params.direction === 'asc','sorting_desc': params.field === 'email' && params.direction === 'desc' }" tabindex="0" aria-controls="administrators"
                                        @click="sort('email')"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Email: cliquez pour trier par ordre croissant">
                                        {{ __('ui.administrator.Email') }}
                                    </th>
                                    <th class="col-1" tabindex="0" aria-controls="administrators">
                                        Informations
                                    </th>
                                    <th class="col-3" tabindex="0" aria-controls="administrators"
                                        rowspan="1"
                                        colspan="1">
                                        {{ __('ui.administrator.Role') }}
                                    </th>
                                    <th class="col-1" tabindex="0" aria-controls="administrators" rowspan="1"
                                        colspan="1">{{ __('ui.administrator.Actions') }}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr :class="{'table-primary' : props.currentItem === user}" v-for="(user, index) in administrators.data" :key="user.id">
                                    <td class="dtr-control sorting_1" tabindex="0">{{user.firstname}}
                                    </td>
                                    <td class="dtr-control sorting_1" tabindex="0">{{user.lastname}}
                                    </td>
                                    <td class="dtr-control sorting_1" tabindex="0">{{user.email}}
                                    </td>
                                    <td>

                                        <InformationsUser :infos="user.user_metas" :role="user.roles[0].slug"/>
                                    </td>
                                    <td>
                                        <span v-if="Object.keys(user.roles).length">
                                            <span class="mr-1 badge badge-info" v-for="(role, index) in user.roles" :key="index">
                                            {{ __(role.name) }}
                                            </span>
                                        </span>
                                        <span v-else><small class="text-muted"><em>{{ __('ui.administrator.NoRole') }}</em></small></span>

                                        <template v-if="isAccountActif(user) !== null">
                                            <span class="badge" :class="{'badge-warning': isAccountActif(user) === 0, 'badge-success': isAccountActif(user) === 1}">{{ isAccountActif(user) === 0 ? "Inactif" : "Actif" }}</span>
                                        </template>

                                    </td>
                                    <td class="text-center">
                                        <button class="btn btn-default btn-sm"  type="button" @click="editItem(user)"><i class="fas fa-pen"></i></button>
                                        <button v-show="$page.props.auth.user.id !== user.id" :disabled="disabledStateDeleteButton(user)" @click="openSupressModal(user)" class="btn btn-default btn-sm"><i class="fas fa-trash-alt"></i></button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2 mb-sm-0" id="administrators_info" role="status"
                             aria-live="polite">{{ __('ui.administrator.DisplayOf') }} {{ administrators.from }} {{ __('ui.administrator.To') }} {{ administrators.to }} {{ __('ui.administrator.On') }}
                            {{ administrators.total }} {{ administrators.total > 1 ?  __('ui.administrator.Entries')  : __('ui.administrator.Entry') }}
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-7">
                        <Pagination @paginate="editItem(null)" class="mt-2 mb-sm-0" id="administrators_paginate" :items="administrators"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



