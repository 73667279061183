<script setup>
import BoAuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import {Head, usePage} from '@inertiajs/inertia-vue3';
import {onEnterOpacity, onBeforeEnterOpacity} from "@/Helpers/animations";
import {computed, onMounted, ref} from "vue";
import DiagnosticsList from "@/Pages/Diagnostics/partials/List.vue";
import ContextualActions from "@/Pages/Diagnostics/partials/ContextualActions.vue";
import AddEditToolbox from "@/Pages/Diagnostics/partials/AddEditToolbox.vue";

const itemToEdit = ref(null);
const selected_diagnostics_in_list = ref([]);

const edit = (item) => {
    itemToEdit.value = item;
}
const display_add_edit_toolbox = false;
const diagnosticsListClickHandler = (selected_diagnostics) => selected_diagnostics_in_list.value = selected_diagnostics;

const contextualActionComponent = ref(null);

const props = defineProps({
    diagnostics:{
        type:Object,
        required:true,
    },
    diagnostics_for_sync:{
        type:Array,
        required:true,
    },
    exploitants:{
        type:Array,
        required:true,
    },
    filters:{
        type:Object,
        required:true,
    },
})

</script>

<template>
    <Head title="Gestion des diagnostics"/>

    <BoAuthenticatedLayout>
        <template #page_title>Gestion des diagnotics</template>
        <transition @before-enter="onBeforeEnterOpacity"
                    @enter="onEnterOpacity"
                    appear mode="out-in">
            <div class="container-fluid">
                <div class="row animate">
                    <div class="col">
                        <p class="mb-0" v-if="display_add_edit_toolbox">Utilisez le formulaire <span class="d-inline d-sm-none">ci-dessous</span><span class="d-none d-sm-inline">de droite</span> pour créer un diagnostic</p>
                        <p class="mb-4">

                            Sélectionnez un ou plusieurs diagnostic(s) pour activer les actions contextuelles :
                        </p>
                        <ContextualActions ref="contextualActionComponent"
                                           :selected_diagnostics="selected_diagnostics_in_list"/>
                    </div>
                </div>
                <div class="row">
                    <div :class="{'col-sm-8': display_add_edit_toolbox, 'col-sm-12': !display_add_edit_toolbox}">
                        <DiagnosticsList :current-item="itemToEdit"
                                         :display_edit_button="display_add_edit_toolbox"
                                         @edit-item="edit"
                                         @click-on-diagnostic="diagnosticsListClickHandler"
                                         @ask_for_sync_to_local="contextualActionComponent.launch_sync()"
                        />
                    </div>
                    <div class="col-sm-4" v-if="display_add_edit_toolbox">
                        <AddEditToolbox :item-to-edit="itemToEdit"
                                        @cancel-editing="edit(null)"/>
                    </div>
                </div>
            </div>
        </transition>
    </BoAuthenticatedLayout>
</template>
