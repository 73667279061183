<script setup>
import {computed, onMounted} from "vue";
import {usePage} from "@inertiajs/inertia-vue3";
import {find} from "lodash";

const props = defineProps({
    infos: {
        type: Object,
    },
    role: {
        type: String,
    }
});

const nom_organisme_referent = computed(() => {
    const organisme_name = props.infos.filter((metas) => {
        return metas.meta_key === "organisme_name"
    }).shift();
    return organisme_name.meta_value;
});
const nom_organisme_rattachement = computed(() => {
    const organisme_id = props.infos.filter((meta) => meta.meta_key === "organisme_id").shift().meta_value,
        all_organismes = usePage().props.value.organismes_list,
        organisme = all_organismes.filter((organisme) => organisme.user_id == organisme_id).shift();
    return organisme.meta_value;
});
const num_dpt = computed(() => {
    const dpt = find(props.infos, info => info.meta_key==='dpt_exploitation');
    if(dpt){
        return dpt.meta_value;
    }
    return null;
})
</script>

<template>
    <span class="badge badge-secondary">
        <template v-if="role==='referent'">{{ nom_organisme_referent }}</template>
        <template v-if="role==='conseiller' || role==='exploitant'">{{ nom_organisme_rattachement }}</template>
    </span>
    <template v-if="role==='exploitant'">
        <br><small>Dpt : {{ num_dpt }}</small>
    </template>
</template>
